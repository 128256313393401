import React, { useState } from "react";
import { routes } from "../configs";
import { NavLink } from "react-router-dom";

export default function Menu(props) {
  const { language } = props;
  const [scopeLanguage, setScopeLanguage] = useState(language);
  const [open, setOpen] = useState(false);
  const handleButton = () => {
    setOpen(!open);
    props.unblend();
  };

  const links = routes
    .filter((r) => r.path !== "/")
    .map((route) => (
      <NavLink
        key={route.path}
        onClick={handleButton}
        exact={true}
        to={route.path}
        activeClassName="selected"
      >
        {route.label[scopeLanguage] ? route.label[scopeLanguage].toUpperCase(): route.label.fr}
      </NavLink>
    ));

  const changeLanguage = () => {
    setScopeLanguage(scopeLanguage === "fr" ? "en" : "fr");
    props.setMasterLanguage(scopeLanguage === "fr" ? "en" : "fr");
  };

  const changeLanguageButton = (
    <div className="clickable" onClick={changeLanguage}>
      {scopeLanguage === "fr" ? "WE CAN SPEAK ENGLISH" : "JE PARLE FRANCAIS"}
    </div>
  );

  return (
    <>
      <div
        onClick={handleButton}
        className={`col menu-button clickable ${open ? "opened" : "closed"}`}
      >
        <div className="tiny-menu-bar"></div>
        <div className="tiny-menu-bar"></div>
        <div className="tiny-menu-bar"></div>
      </div>

      <div
        className={`menu-list-container col ${open ? "displayed" : "hidden"}`}
      >
        <ul className="col">
          <h2 className="menu-title">MENU</h2>
          {links}
          {changeLanguageButton}
        </ul>
      </div>
    </>
  );
}
