import React, { useEffect } /*, { useState }*/ from "react";

import img1 from "../img/films/1.png";
import img2 from "../img/films/2.png";
import img3 from "../img/films/3.png";
import img4 from "../img/films/4.png";
import img5 from "../img/films/5.png";

// import img5 from "../img/films/4bis.png";

import img1_lowdef from "../img/films/1_lowdef.png";
import img2_lowdef from "../img/films/2_lowdef.png";
import img3_lowdef from "../img/films/3_lowdef.png";
import img4_lowdef from "../img/films/4_lowdef.png";
import img5_lowdef from "../img/films/5_lowdef.png";

// import img5_lowdef from "../img/films/4bis_lowdef.png";

import { textContent } from "../configs";



import ImageRenderer from "./ImageRenderer"

export default function Films(props) {
  useEffect(() => {
    document.title = "Alice Neuville | Films";
    document.querySelector(".header-container .logo") && document.querySelector(".header-container .logo").classList.remove("hidden")
    window.scrollTo(0, 0);
  })
  const { masterLanguage } = props;

  const galery = [
    { img: img1, title: "Serpentines", img_lowdef:img1_lowdef, link:"https://vimeo.com/283910031" },
    { img: img2, title: "Kiss the rail", img_lowdef:img2_lowdef, link:"https://vimeo.com/196163101" },
    { img: img3, title: "Blue Moon", img_lowdef:img3_lowdef, link:"https://vimeo.com/151751830" },
    { img: img4, title: "Blue Lagoon", img_lowdef:img4_lowdef, link:"https://vimeo.com/176448623" },
    { img: img5, title: "Wolf Kiss", img_lowdef:img5_lowdef, link:"https://vimeo.com/514207860?share=copy" },
  ].map((x) => {
    return (
      <div key={x.img} className="film-galery-item clickable">

            <h3 className="img-titles">{x.title.toUpperCase()}</h3>
        <a href={x.link} target="_blank" rel="noreferrer">
            <ImageRenderer imageHD={x.img} imageLD={x.img_lowdef} customClass={""}/>
        </a>
      </div>
    );
  });

  const rollingText = (
    <div className="roller row">
      <div className="rolling row">
        <div> {textContent.films.roller[masterLanguage]}</div>
      </div>
    </div>
  );
  return (
    <div>
      {rollingText}
      <div
        className="fullsize-galery col"
      >
        {galery}
      </div>
    </div>
  );
}
