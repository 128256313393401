import React from "react"
import ImageRenderer from "./ImageRenderer";


export default function DecorsSubSection(props) {

    const parseNumberInName = (name) => {
        // replace {n} by <span class...>n</span>
        return (/{(.*?)}/).test(name) ? name.split(/{(.*?)}/).map(x => (/\d/).test(x) ? <span className="parse-number">{x}</span> : x) : name;
    }

    const {sectionTitle, feed, allImages, category} = props

    const orderedLinks = feed.sort((a,b) => a.index - b.index)

    const orderedLinksWithImages = orderedLinks.map(item => {
        const image = allImages.find(image => image.default.split("media/")[1].split(".")[0].toLowerCase() === item.title.toLowerCase())
        const lowDefImage = allImages.find(image => image.default.split("media/")[1].split(".")[0].toLowerCase() === item.title.toLowerCase()  + "_lowdef")
        return {...item, image, lowDefImage}
    }) 

    const gridCells = orderedLinksWithImages.map((cell, i) => {
        
        const l = String.fromCharCode(i + 65).toLowerCase()

        return cell.image && (

            <a target="_blank" rel="noreferrer" href={cell.link} key={i} className={`${l} decors-img`} style={{gridArea: `${l}`,}}>
                
                <h3 className="img-titles">

                    {parseNumberInName(cell.title.replace(/_\d/,'').replace('@',"'").toUpperCase().split("_").join(" "))}
                
                </h3>

                <ImageRenderer imageHD={cell.image.default} imageLD={cell.lowDefImage.default} customClass={""}/>

            </a>
        )
    })

    return <div>

        <h2 className="drive-header">{sectionTitle}</h2>
        <div className={`${category} grid-container`}>{gridCells}</div>

    </div>
}

