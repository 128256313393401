import React, {useEffect, useState} from "react";
// import img from "../img/films/2.png"
// import imgLowDef from "../img/films/2_lowdef.png"

import logo_animate from "../img/home/reallyspeedlogo_2.gif"
import go from "../img/home/go.png"

// import logo_stopped from "../img/home/logostop.png"


// import { routes } from "../configs";
import { NavLink } from "react-router-dom";
// import ImageRenderer from "./ImageRenderer";





export default function Home(props) {
  // const { masterLanguage } = props;

  document.title = "Alice Neuville";

  const [btnClass, setClass] = useState('')

  const [gif, setGif] = useState(logo_animate)

  

  useEffect(() => {
  //   setTimeout(() => {
  //     setGif(logo_stopped)
  //   }, 8500)
    setTimeout(() => {
      setClass('fadeIn')
    }, 2000)
  },[])



  return <div className="home col full-height">

    <div className="background-text home col">



    {/* <NavLink
      exact={true}
      to={"/films"}>
    <div className="home-logo">LOGO</div>
      <ImageRenderer effectOn={isHover} imageHD={img} imageLD={imgLowDef} customClass={"home-img"}/>
    </NavLink> */}

    <NavLink
      exact={true}
      to={"/films"}>
    <img className="home-logo-animate no-effect" src={gif} alt="aliceneuville"/>
    <div className={`home-go-btn ${btnClass}`}>
      <img src={go} alt="enter"/>
    </div>
    </NavLink>
  </div>

  </div>
}
