import React, { useState } from "react";
import Menu from "./Menu";
import { Link } from "react-router-dom";

export default function Header(props) {

  const {masterLanguage} = props
  const [blending, setBlending] = useState(true);

  const unblend = () => setBlending(!blending);

  return (
    <div className="col">
      <div
        className={`header-container row ${blending && "blended"} ${
          blending &&
          window.location.href.split("/").pop() === "clients" &&
          "white"
        }`}
      >
        <Link to={"/"} style={{pointerEvents:'all'}}>
          <div className="logo"></div>
        </Link>
        <Menu
          language={masterLanguage}
          unblend={unblend}
          setMasterLanguage={props.setMasterLanguage}
        />
      </div>
    </div>
  );
}
