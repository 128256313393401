import React, { useEffect, useState } from "react";

export default function Contact() {
  window.scrollTo(0, 0);
  useEffect(() => {
    document.title = "Alice Neuville | Contact";
    document.querySelector(".header-container .logo") && document.querySelector(".header-container .logo").classList.add("hidden")
  },[])

  const [hover, isHover] = useState(false)

  // console.log('document.querySelector(".header-container .logo"): ', document.querySelector(".header-container .logo"));
  return (
    <div>
      <div className="contact-links col">
        <a href="mailto:neuvillealice@yahoo.fr" onMouseOver={() => isHover(true)} onMouseLeave={() => isHover(false)}>{hover ? 'neuvillealice@yahoo.fr' : 'COURRIEL'}</a>
        <a
          href="https://www.instagram.com/alice.neuville.neuville.alice/"
          target="_blank"
          rel="noopener noreferrer"
        >
          INSTAGRAM
        </a>
      </div>
    </div>
  );
}
