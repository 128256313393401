import React from "react";
import { Route, Switch } from "react-router-dom";
import { routes } from "../configs";

export default function Routing(props) {
  const { masterLanguage } = props;
  const routings = routes.map((route) => (
    <Route
      key={route.path}
      exact={true}
      path={route.path}
      component={() => <route.component masterLanguage={masterLanguage} />}
    />
  ));
  return <Switch>{routings}</Switch>;
}
