import React, {useState, useEffect} from 'react'



export default function Draggable(props){

    const {img, classElement, startPosition, startIndex} = props

    const [position, setPosition] = useState(startPosition)
    const [mouseDown, setMouseDown] = useState(false)
    const [zIndex, setZIndex] = useState(startIndex)

    const [helperPos, setHelperPos] = useState(null);
    
    const handleMouseDown = event => {
        setHelperPos({x:event.pageX - position.x, y:event.pageY - position.y})
        setMouseDown(true)
    }

    useEffect(() => {
        if(mouseDown){
            const draggableElements = document.querySelectorAll('.bio-draggable-element')
            const maxIndex = Math.max(...[...draggableElements].map(element => element.style.zIndex))
            if(zIndex === maxIndex) return
            const newIndex = maxIndex + 1
            setZIndex(Math.min(newIndex, 99));
        }
    }, [mouseDown])

    const changePosition = (event) => {
        if(!mouseDown) return
        const {pageX, pageY} = event
        const newY = pageY - helperPos.y 
        const newX = pageX - helperPos.x 
        setPosition({
            x: Math.max(0, Math.min(newX, window.innerWidth - 200)),
            y: Math.max(0, Math.min(newY, window.innerHeight - 200))
        })
    }

    const formatText = props.text && props.text.split('<BREAK>').map(para => <p key={para}>{para}</p>)
    
    return(
            <div className={`${classElement} ${mouseDown ? 'moving' : ''}`}
            style={{position: 'absolute', left: position.x, top: position.y, zIndex: zIndex}}
            onMouseDown={handleMouseDown}
            onMouseUp={() => setMouseDown(false)}
            onMouseLeave={() => setMouseDown(false)}
            onMouseMove={changePosition}
            >
                <img className="no-effect" src={img} alt="bio" draggable="false"/>
                {props.subImg && (
                    <div className="row-sub_img">

                <img className="no-effect sub_img" src={props.subImg} alt="bio" draggable="false"/>
                    </div>
                )}
                {formatText && (
                    <div className="bio-text-content">{formatText}</div>
                )}
            </div>
    )
}