import React from "react"
// import { BrowserRouter } from "react-router-dom";
import Routing from "./Routing";
import Header from "./Header";


export default function MainContainer(props) {
    const {language, setMasterLanguage} = props
    return<div className="App">

    <Header
      setMasterLanguage={setMasterLanguage}
      masterLanguage={language}
    />
    
    <div className="fullsize scrollableY">
          <Routing masterLanguage={language} />
        </div>
      </div>

    
}