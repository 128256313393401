import React, { useEffect } from "react";
// import GridTemplate from "./GridTemplate";
import DecorsSubSection from "./DecorsSubSection"

import {
  textContent,
  videoClipLinks,
  commercialsLinks,
  moviesLinks,
} from "../configs";
// import VideoMiniature from "./VideoMiniature";

export default function Decors(props) {
  useEffect(() => {
    document.title = "Alice Neuville | Decors";
    document.querySelector(".header-container .logo") && document.querySelector(".header-container .logo").classList.remove("hidden")
  })

  
  window.scrollTo(0, 0);

  const { masterLanguage } = props;


  const rollingText = (
    <div className="roller row">
      <div className="rolling row">
        <div> {textContent.decors.roller[masterLanguage]}</div>
      </div>
    </div>
  );

  const getAllClipImages = () => {
    const importAll = (r) => r.keys().map(r);
    const images = importAll(
      require.context(`../img/decors/clips2/`, false, /\.(png|jpe?g|svg)$/)
    );
    return images;
  };

  const getAllFictionImages = () => {
    const importAll = (r) => r.keys().map(r);
    const images = importAll(
      require.context(`../img/decors/fiction2/`, false, /\.(png|jpe?g|svg)$/)
    );
    return images;
  };

  const getAllPubImages = () => {
    const importAll = (r) => r.keys().map(r);
    const images = importAll(
      require.context(`../img/decors/pub2/`, false, /\.(png|jpe?g|svg)$/)
    );
    return images;
  };

  return (
    <div>
      {rollingText}

      <DecorsSubSection category={"pub"} sectionTitle={masterLanguage === "fr" ? "PUBLICITES" : "COMMERCIALS"} feed={commercialsLinks} allImages={getAllPubImages()}/>

      <DecorsSubSection category={"clip"} sectionTitle={masterLanguage === "fr" ? "VIDEOSCLIPS" : "VIDEO CLIPS"} feed={videoClipLinks} allImages={getAllClipImages()}/>

      <DecorsSubSection category={"movie"} sectionTitle={masterLanguage === "fr" ? "COURTS METRAGES" : "MOVIES"} feed={moviesLinks} allImages={getAllFictionImages()}/>
    </div>
  );
}
