import React, { useEffect } from "react";
import { textContent, clientsTextContent } from "../configs";

export default function Clients(props) {
  useEffect(() => {
    document.querySelector(".header-container .logo") && document.querySelector(".header-container .logo").classList.remove("hidden")
    window.scrollTo(0, 0);
    document.title = "Alice Neuville | Clients";
  },[])
  
  const { masterLanguage } = props;
  const rollingText = (
    <div className="roller row">
      <div className="rolling white-content row">
        <div> {textContent.clients.roller[masterLanguage]}</div>
      </div>
    </div>
  );

  const musicArtits = clientsTextContent.musicArtists.map((x) => (
    <li key={`musicartist${x}`}>{x.toUpperCase()}</li>
  ));

  const directors = clientsTextContent.directors.map((x) => (
    <li key={`directors${x}`}>{x.toUpperCase()}</li>
  ));

  const productions = clientsTextContent.productions.map((x) => (
    <li key={`productions${x}`}>{x.toUpperCase()}</li>
  ));

  const brands = clientsTextContent.brands.map((x) => (
    <li key={`brands${x}`}>{x.toUpperCase()}</li>
  ));
  return (
    <div className="white-content">
      {rollingText}

      {/* SECTION */}
      <h2 className="drive-header">
        {masterLanguage === "fr" ? "ARTISTES MUSICAUX" : "MUSIC ARTISTS"}
      </h2>
      <ul>{musicArtits}</ul>
      {/* END SECTION */}

      {/* SECTION */}
      <h2 className="drive-header">
        {masterLanguage === "fr" ? "REALISATEURS" : "DIRECTORS"}
      </h2>
      <ul>{directors}</ul>
      {/* END SECTION */}

      {/* SECTION */}
      <h2 className="drive-header">
        {masterLanguage === "fr" ? "PRODUCTIONS" : "PRODUCTIONS"}
      </h2>
      <ul>{productions}</ul>
      {/* END SECTION */}

      {/* SECTION */}
      <h2 className="drive-header">
        {masterLanguage === "fr" ? "MARQUES" : "BRANDS"}
      </h2>
      <ul>{brands}</ul>
      {/* END SECTION */}
    </div>
  );
}
