import React, { useEffect, useState } from "react";
import { textContent, bioTextContent } from "../configs";

import id_img from "../img/bio2/id.png";
import cam_gif from "../img/bio/webcam_gif.gif";
import cam_btn from "../img/bio2/cam_play.png";
import bio_header from "../img/bio2/bio_text.png";



import Draggable from "./Draggable";

export default function Bio(props) {
    const {masterLanguage} = props

  window.scrollTo(0, 0);



  useEffect(() => {
    document.title = "Alice Neuville | Bio";
    document.querySelector(".header-container .logo") && document.querySelector(".header-container .logo").classList.remove("hidden")

    document.querySelector('.header-container').style.zIndex = 9999
},[])



  const rollingText = (
    <div className="roller row">
      <div className="rolling row">
        <div> {textContent.bio.roller[masterLanguage]}</div>
      </div>
    </div>
  );

  return (

    <div className="bio-container">
        <div style={{zIndex:9998, position:'absolute', pointerEvents:'none'}}>
        {rollingText}
        </div>
      <Draggable img={cam_gif} startIndex={1} subImg={cam_btn} startPosition={{x:300,y:40}} classElement="bio-draggable-element bio-cam-gif"/>
      <Draggable img={id_img} startIndex={2} startPosition={{x:30,y:60}} classElement="bio-draggable-element bio-id-img"/>
      <Draggable img={bio_header} startIndex={2} text={bioTextContent[masterLanguage]} startPosition={{x:900,y:160}} classElement="bio-draggable-element bio-text"/>
    </div>
  );
}
