import React from "react"
import ImageLoader from "./ImageLoader"

 const ImageRenderer = (props) => {
     const {imageHD, imageLD, effectOn, customClass} = props

    const [src, { blur }] = ImageLoader(
    imageLD,
      imageHD
    );
    return (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img
      className={`${effectOn && "home-img-effect"} ${customClass}`}

        src={src}
        style={{
          width: "100%",
          filter: blur
            ? "blur(10px)"
            : "none",
          transition: "filter 0.2s ease-out"
        }}
      />
    )
  }
  
  export default ImageRenderer