import "./App.css";
import "./menu.css";
import "./grids.css";
import "./roller.css";
import "./films.css";
import "./contact.css";
import "./smallScreen.css";
import "./bio.css";

import { BrowserRouter } from "react-router-dom";
import React, { useState } from "react";
import MainContainer from "./components/MainContainer";
import Home from "./components/Home";
import { Route, Switch } from "react-router-dom";

function App(props) {
  const [scopeLanguage, setScopeLanguage] = useState(
    /*navigator.language || navigator.userLanguage ||*/ "fr"
  );
  const setMasterLanguage = (lang) => setScopeLanguage(lang);

  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact={true}
          path={"/"}
          component={() => <Home masterLanguage={scopeLanguage} />}
        />
        <MainContainer
          language={scopeLanguage}
          setMasterLanguage={setMasterLanguage}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
