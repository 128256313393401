import Films from "./components/Films";
import Decors from "./components/Decors";
import Clients from "./components/Clients";
import Contact from "./components/Contact";
import Bio from "./components/Bio";
// import Home from "./components/Home";

const personalVar = {
  // to update bio easily
  age: { fr: "trente", en: "thirty" },
};

export const routes = [
  {
    path: "/about",
    label: { fr: "Qui suis-je?", en: "About me" },
    component: Bio,
  },
  {
    path: "/films",
    label: { fr: "Je realise des films", en: "I make movies" },
    component: Films,
  },
  {
    path: "/decors",
    label: { fr: "Je fais des decors", en: "I do set design" },
    component: Decors,
  },
  {
    path: "/clients",
    label: { fr: "Ma liste de clients", en: "My clients" },
    component: Clients,
  },
  {
    path: "/contact",
    label: { fr: "Tu peux me contacter", en: "You can contact me" },
    component: Contact,
  },
];

export const textContent = {
  films: {
    roller: {
      fr: "JE REALISE DES FILMS DE FICTION ET DES FILMS EXPERIMENTAUX",
      en: "I DIRECT SHORT MOVIES AND EXPERIMENTAL VIDEOS",
    },
  },
  decors: {
    roller: {
      fr:
        "JE FAIS DES DECORS DE PUBLICITE - CLIP DE MUSIQUE - FILMS DE FICTION",
      en: "I CREATE SET DESIGN FOR COMMERCIALS - VIDEO CLIP - MOVIES",
    },
  },
  clients: {
    roller: {
      fr: "MA LISTE DE CLIENTS EST ICI",
      en: "CLIENTS LIST",
    },
  },
  bio: {
    roller: {
      fr: "QUI SUIS-JE?",
      en: "ABOUT ME",
    },
  },
  contact: {},
};

export const videoClipLinks = [
  {
    link: "https://www.youtube.com/watch?v=0wnWvxO-hE4",
    title: "lomepal_1",
    index: 21,
  },

  {
    link: "https://www.youtube.com/watch?v=MlsmPrmOZO4",
    title: "lomepal_club",
    index: 15,
  },
  {
    link: "https://www.youtube.com/watch?v=Aq06LFpFpcE",
    title: "lewis_of_man",
    index: 0,
  },
  {
    link: "https://www.youtube.com/watch?v=KjMArCF2Dv0",
    title: "teeers",
    index: 10,
  },
  {
    link: "https://www.youtube.com/watch?v=WPTC9Yu1ZKE",
    title: "julien_granel",
    index: 13,
  },
  {
    link: "https://www.youtube.com/watch?v=gbqyZrgish4",
    title: "lefa_x_orelsan_1",
    index: 1,
  },
  {
    link: "https://www.youtube.com/watch?v=gbqyZrgish4",
    title: "lefa_x_orelsan_2",
    index: 2,
  },
  {
    link: "https://www.youtube.com/watch?v=OhqUMFj51X0",
    title: "vendredi_sur_mer_1",
    index: 11,
  },
  {
    link: "https://www.youtube.com/watch?v=OhqUMFj51X0",
    title: "vendredi_sur_mer_2",
    index: 12,
  },
  {
    link: "https://www.youtube.com/watch?v=RMWBriHwVrI",
    title: "bon_entendeur",
    index: 14,
  },
  {
    link: "https://www.youtube.com/watch?v=J2tIE5ARdRk",
    title: "raymond",
  },
  {
    link: "https://www.youtube.com/watch?v=Pt-_hOTp6_I",
    title: "lauri_darmon",
  },
  {
    link: "https://www.youtube.com/watch?v=kX30U0FmTuo",
    title: "eddy_de_pretto",
    index: 19,
  },
  { link: "https://vimeo.com/328978986", title: "triplego_1", index: 8 },
  { link: "https://vimeo.com/328978986", title: "else_2", index: 9 },

  {
    link: "https://www.youtube.com/watch?v=wRf4o772lCw",
    title: "regina_demina_1",
    index: 17,
  },
  {
    link: "https://www.youtube.com/watch?v=hbAwm8rOGXI ",
    title: "dune_x_crayon_feat_ichon_1",
    index: 3,
  },
  {
    link: "https://www.youtube.com/watch?v=hbAwm8rOGXI ",
    title: "dune_x_crayon_feat_ichon_2",
    index: 4,
  },
  {
    link: "https://www.youtube.com/watch?v=norMgcLGWQo",
    title: "pr{2}b_1",
    index: 18,
  },
  {
    link: "https://www.youtube.com/watch?v=C7U6johrXKY",
    title: "pr{2}b_2",
    index: 16,
  },
  {
    link: "https://www.youtube.com/watch?v=JO9bYDaRMsI",
    title: "brodinski_feat_bloody_jay_1",
    index: 5,
  },
  {
    link: "https://www.youtube.com/watch?v=JO9bYDaRMsI",
    title: "brodinski_feat_bloody_jay_2",
    index: 6,
  },
  {
    link: "https://www.youtube.com/watch?v=JO9bYDaRMsI",
    title: "brodinski_feat_bloody_jay_3",
    index: 7,
  },
  {
    link: "https://www.youtube.com/watch?v=bJ2wtjNz6sk",
    title: "darius_x_wayne_snow",
    index: 20,
  },
];

export const commercialsLinks = [
  {
    link:
      "https://www.youtube.com/watch?v=uN-Hmrl9wl0&feature=emb_title&ab_channel=ParfasExclusieveParfumerie",
    title: "Juliette_has_a_gun",
    index: 1,
  },
  {
    link: "https://www.partizan.com/video/gucci-x-mad-spooky-gucci-glasses",
    title: "Gucci_x_mad_1",
    index: 2,
  },
  {
    link: "https://www.partizan.com/video/gucci-x-mad-spooky-gucci-glasses",
    title: "Gucci_x_mad_2",
    index: 3,
  },
  {
    link: "https://www.youtube.com/watch?v=DCwF8NcT5QE",
    title: "free_2",
    index: 4,
  },
  {
    link: "https://www.youtube.com/watch?v=DCwF8NcT5QE",
    title: "free_1",
    index: 6,
  },
  {
    link: "https://www.youtube.com/watch?v=0iaoIAt5UNY&t=3s",
    title: "Badoo_1",
    index: 9,
  },
  {
    link: "https://www.youtube.com/watch?v=7qTacZ9eP30&t=114s",
    title: "Badoo_2",
    index: 8,
  },
  // {
  //   link: "https://www.youtube.com/watch?v=TlzS_SqVzrc&t=49s",
  //   title: "Badoo_3",
  // },
  // {
  //   link: "https://www.youtube.com/watch?v=e2V_Nwb9oxM",
  //   title: "Badoo_4",
  // },
  {
    link: "https://www.youtube.com/watch?v=dTYELkhmHbY",
    title: "Badoo_0",
    index: 12,
  },
  {
    link:
      "https://www.tanke.fr/wp-content/uploads/2018/09/ezgif-5-b82922feff23.gif",
    title: "Gucci_bloom",
    index: 7,
  },
  { link: "https://youtu.be/SIwopCD4xT0", title: "Soupline_0", index: 11 },
  { link: "https://youtu.be/z5Q6_Sh98KQ", title: "Soupline_1", index: 5 },
  { link: "https://youtu.be/z5Q6_Sh98KQ", title: "Soupline_2", index: 10 },
];
export const moviesLinks = [
  {
    link: "https://www.youtube.com/watch? v=7qTacZ9eP30&t=7s",
    title: "en_vrai_c@etait_bien_1",
    index: 1,
  },
  {
    link: "https://www.youtube.com/watch? v=7qTacZ9eP30&t=7s",
    title: "en_vrai_c@etait_bien_2",
    index: 2,
  },
  {
    link: "https://vimeo.com/283910031 ",
    title: "serpentines_1",
    index: 3,
  },
  {
    link: "https://vimeo.com/283910031 ",
    title: "serpentines_2",
    index: 4,
  },
  {
    link: "https://vimeo.com/283910031 ",
    title: "serpentines_3",
    index: 5,
  },
  {
    link: "https://www.youtube.com/watch?v=TlzS_SqVzrc&t=226s",
    title: "en_vrai_c@etait_bien_3",
    index: 6,
  },
  {
    link: "https://vimeo.com/176448623 ",
    title: "blue_lagoon_1",
    index: 7,
  },
  {
    link: "https://vimeo.com/176448623 ",
    title: "blue_lagoon_2",
    index: 8,
  },
  {
    link: "https://vimeo.com/176448623 ",
    title: "blue_lagoon_3",
    index: 9,
  },
  {
    link: "https://vimeo.com/196163101 ",
    title: "kiss_the_rail",
    index: 10,
  },
];

export const bioTextContent = {
  fr: `HELLO !<BREAK> 
    J’ai ${personalVar.age.fr} ans et je vis à Paris, en France.<BREAK>
    
    A mes vingt ans, lors de mon diplôme de technicien supérieur à ESAA DUPERRE, j’étais très intéressée par la mode, les textiles et les matériaux. Pour présenter mes travaux, je les filmais.
    Créer des objets pour les faire vivre dans l’image et nulle part ailleurs, me plaisait.<BREAK>
    
    Je me suis instinctivement orientée vers le cinéma et j’ai obtenu une licence à l’ UNIVERSITE SORBONNE NOUVELLE, toujours à Paris.<BREAK> 
    
    Enfin, j’ai passé un master réalisation à L’ECOLE DE LA CITE DE LUC BESSON à Saint-Denis.<BREAK>
    
    Depuis, je travaille dans la décoration et le costume de cinéma, tout en réalisant mes films en parallèle. `,
  en: `HELLO !<BREAK>
  I am ${personalVar.age.en} years old and I live in Paris, France.<BREAK> 

  At twenty, I studied textile design at ESAA DUPERRE, in Paris, and was very interested in fashion, and the use of different textiles and materials. I also used to film the assignments and creations to show them. 
  I loved creating objects and bringing them to life on screen, and nowhere else.<BREAK> 
  
  That's why I instinctively oriented myself towards cinema and I graduated from a licence degree (in film studies) at UNIVERSITE SORBONNE NOUVELLE, still in Paris.<BREAK>  
  
  Finally, I completed a master's degree in directing at  L’ECOLE DE LA CITE, a school created by LUC BESSON, in Saint Denis.<BREAK> 
  
  Since then, I have been working as a set designer and as a costume designer on movies, shorts videos or advertising, while keeping directing my films on the side.<BREAK>`,
};

export const clientsTextContent = {
  musicArtists: [
    "Bon Entendeur",
    "Brodinski",
    "Dune x Crayon Feat Ichon",
    "Eddy de Pretto",
    "Else",
    "Julien Granel",
    "Lewis of Man",
    "Laurie Darmon",
    "Lomepal",
    "Pr2B",
    "Ravmond",
    "Regina Demina",
    "Triplego",
    "Vendredi sur mer",
  ],
  directors: [
    "Julien Granel",
    "Alice Kong",
    "Athys de Galzain",
    "Auguste Bas",
    "Dario Fau",
    "Eleonore Wismes",
    "Eliott Fournié",
    "Florent Koziel",
    "Global",
    "Hamadou Frederic Balde",
    "Henri Gander",
    "Léo Schrepel",
    "NDVD",
    "Pauline Rambeau de Baralon",
  ],
  productions: [
    "Partizan",
    "B.o.x",
    "Chi-fou-mi",
    "Currents",
    "Eddy",
    "Europacorp",
    "Eskwad",
    "Frenzy",
    "Ilomba",
    "Les Filmes Pelléas",
    "Les Mauvaises filles",
    "La Blogotheque",
    "Mandoline Film",
    "Phantasm",
    "Sleak",
    "Solab",
  ],
  brands: [
    "Free",
    "Gucci x Mad",
    "Juliette has a gun",
    "Mc Donald's",
    "M&M's",
    "SNCF",
    "Soupline",
    "Yves Saint Laurent",
  ],
};
/**
 https://vimeo.com/283910031  serpentines
https://vimeo.com/196163101 kiss the rail
https://vimeo.com/176448623 blue lagoon 
 */
